import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Form } from "semantic-ui-react";
import { getTomorrowDate } from "../../../../libs/common_utils";
import { Config } from "../../../../config/api";

const OnSiteDateTimeControl = ({onChange, end_date, error, is_edit, config}) => {
	const intl = useIntl();
	return is_edit? <Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_END_DATE",
					defaultMessage: "End Date",
				})}
			</label>
			<div className="display-inline">{end_date.toLocaleString()}</div>
		</Form.Field> :
		<Form.Field inline className="onsite_datepicker"
								error={Boolean(error)}
								required>
			<label style={{"top": "8px", "position": "relative"}}>
				{intl.formatMessage({
					id: "LABEL_END_DATE",
					defaultMessage: "End Date",
				})}
			</label>
			<DatePicker
				name="end_date"
				onChange={onChange}
				selected={end_date}
				minDate={getTomorrowDate()}
				showTimeSelect
        timeFormat={config.timeFormat}
        timeIntervals={15}
        timeCaption="time"
        dateFormat={config.fullDateTimeFormat}
				required
			/>
			<div className="custom-error">{error}</div>
		</Form.Field>
};
OnSiteDateTimeControl.propTypes = {
	"onChange": PropTypes.func.isRequired,
	"end_date": PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date)
	]).isRequired,
	"is_edit": PropTypes.bool.isRequired,
	"config": PropTypes.shape({
		"timeFormat": PropTypes.string.isRequired,
		"fullDateTimeFormat": PropTypes.string.isRequired
	}).isRequired,
	"error": PropTypes.string
};

export default OnSiteDateTimeControl;
