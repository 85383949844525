import AbstractService from "../abstract.service";
import { isNil } from "../../libs/common_utils";

export default class AgenciesService extends AbstractService {
	/**
	 * Returns a list of agencies available for current user.
	 */

	static API_ROUTES = {
		"fetchAvailableAgencies": "/agencies",
	};

	/**
	 * Fetch all agencies available for current user
	 */
	async fetchAllAgencies() {
		return this.api_server.get(
			AgenciesService.API_ROUTES.fetchAvailableAgencies,
			{ fetch_all: true }
		);
	}

	/**
	 * Fetch up to 100 agencies available for current user
	 * @param {string} query - an agency name substring to search for
	 */
	async fetchAvailableAgencies(query) {
		return this.api_server.get(
			AgenciesService.API_ROUTES.fetchAvailableAgencies,
			query ? { "agency_name": query } : {}
		);
	}

	/**
	 * @param {number} agencyId - an agency ID to select
	 * @param {string} agencyTitle - selected agency title
	 */
	selectAgency(agencyId, agencyTitle) {
		this.registry.setItem("selected_agency", agencyId);
		this.registry.setItem("selected_agency_title", agencyTitle);
	}

	/**
	 * @param {number} agencyId - an agency ID to select
	 * @param {string} agencyTitle - selected agency title
	 */
	clearAgency() {
		this.registry.removeItem("selected_agency");
		this.registry.removeItem("selected_agency_title");
	}

	/**
	 * @returns {number|null} currently selected agency ID
	 */
	getSelectedAgency() {
		return this.registry.getItem("selected_agency") || null;
	}

	/**
	 * @returns {string} currently selected agency title
	 */
	getSelectedAgencyTitle() {
		return this.registry.getItem("selected_agency_title") || `Agency #${this.registry.getItem("selected_agency")}`;
	}

	/**
	 * verify if agency was chosen
	 * @return {boolean}
	 */
	isAgencySelected() {
		return !isNil(this.getSelectedAgency());
	}
}
