import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { Form, Icon, Input, Select } from "semantic-ui-react";

import { OnlineContext } from "../../../context/online-context";
import InventoryPageContext from "../context";

const dealsStatuses = (intl) => [
  {
    text: intl.formatMessage({
      id: "STATUS_ALL",
      defaultMessage: "All",
    }),
    value: "all",
  },
  {
    text: intl.formatMessage({
      id: "STATUS_ACTIVE",
      defaultMessage: "Active",
    }),
    value: "active",
  },
  {
    text: intl.formatMessage({
      id: "STATUS_INACTIVE",
      defaultMessage: "Inactive",
    }),
    value: "inactive",
  },
];

const dealsTypes = (intl) => [
  {
    text: intl.formatMessage({
      id: "TYPES_ALL",
      defaultMessage: "All",
    }),
    value: "all",
  },
  {
    text: intl.formatMessage({
      id: "TYPE_PMP",
      defaultMessage: "PMP",
    }),
    value: "PMP",
  },
  {
    text: intl.formatMessage({
      id: "STATUS_PG",
      defaultMessage: "PG",
    }),
    value: "PG",
  },
];

function DealFilters() {
  const intl = useIntl();
  const { agency } = useContext(OnlineContext);
  const { state, dispatch, loadDeals } = useContext(InventoryPageContext);
  const filter = state.deals.filter;

  const handleClearIconClick = useCallback(() => {
    filter.setTitle("");
    dispatch({ type: "DEALS_UPDATE_FILTERS", data: { title: "" } });
    if (agency) {
      loadDeals(state, dispatch, state.siteList.pager.page, agency);
    }
  }, [agency, dispatch, filter, loadDeals, state]);

  return (
    <Form
      autoComplete="off"
      noValidate
    >
      <Form.Group>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_STATUS",
              defaultMessage: "Deal Status",
            })}
          </label>
          <Select
            onChange={(_, data) => {
              filter.setStatus(data.value);
              dispatch({
                type: "DEALS_UPDATE_FILTERS",
                data: { status: data.value },
              });
              if (agency) {
                loadDeals(state, dispatch, state.deals.pager.page, agency);
              }
            }}
            options={dealsStatuses(intl)}
            selection
            value={filter.status}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_TYPE",
              defaultMessage: "Deal Type",
            })}
          </label>
          <Select
            options={dealsTypes(intl)}
            onChange={(_, data) => {
              filter.setType(data.value);
              dispatch({
                type: "DEALS_UPDATE_FILTERS",
                data: { dealType: data.value },
              });
              if (agency) {
                loadDeals(state, dispatch, state.deals.pager.page, agency);
              }
            }}
            value={filter.dealType}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_NAME",
              defaultMessage: "Deal Name",
            })}
          </label>
          <Input
            icon={
              filter.title ? (
                <Icon
                  className="link control"
                  name="close"
                  onClick={handleClearIconClick}
                />
              ) : null
            }
            onChange={(_, data) => {
              filter.setTitle(data.value);
              dispatch({ type: "DEALS_UPDATE_FILTERS", data: { title: data.value } });
              if (agency) {
                loadDeals(state, dispatch, state.siteList.pager.page, agency);
              }
            }}
            placeholder={intl.formatMessage({
              id: "HINT_SEARCH_DEAL_NAME",
              defaultMessage: "Search deal by name",
            })}
            value={filter.title}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

export default DealFilters;
