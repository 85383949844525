import PropTypes from "prop-types";
import { useMemo } from "react";
import { Button, Grid, Icon, Loader, Modal } from "semantic-ui-react";

const SuiModal = ({
  hideCloseIcon,
  isContentLoading,
  isOpen,
  isScrollableModalBody,
  modalButtons,
  modalContent,
  modalHeader,
  modalSize,
  onClose,
}) => {
  const scrollableStyle = useMemo(
    () => (isScrollableModalBody ? { maxHeight: "75vh", overflowY: "auto", padding: "25px" } : { padding: "25px" }),
    [isScrollableModalBody],
  );

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      size={modalSize ? modalSize : "small"}
    >
      {modalHeader && (
        <Modal.Header>
          <Grid>
            <Grid.Column width={15}>
              <h2>{modalHeader}</h2>
            </Grid.Column>
            <Grid.Column width={1}>
              {!hideCloseIcon && (
                <Icon
                  aria-label="Close modal"
                  className="control"
                  id="modalCloseIcon"
                  name="close"
                  onClick={onClose}
                />
              )}
            </Grid.Column>
          </Grid>
        </Modal.Header>
      )}

      <Modal.Content style={scrollableStyle}>
        {isContentLoading ? (
          <Loader
            active
            className="workaround"
            inline="centered"
            size="large"
          />
        ) : (
          modalContent
        )}
      </Modal.Content>

      {modalButtons && (
        <Modal.Actions>
          {modalButtons.map((button) => (
            <Button
              key={`modalButton-${button.text}`}
              onClick={button.onClick}
              primary={button.color === "primary"}
            >
              {button.text}
            </Button>
          ))}
        </Modal.Actions>
      )}
    </Modal>
  );
};

SuiModal.propTypes = {
  hideCloseIcon: PropTypes.bool,
  isContentLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isScrollableModalBody: PropTypes.bool,
  modalButtons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  modalContent: PropTypes.node,
  modalHeader: PropTypes.string,
  modalSize: PropTypes.oneOf(["mini", "tiny", "small", "large", "fullscreen"]),
  onClose: PropTypes.func.isRequired,
};

export { SuiModal };
