import { lightFormat } from "date-fns";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";

import { Config } from "../../../config/api";
import { formatMoney, getApiDate, parseAPIDate } from "../../../libs/common_utils";
import { checkNever } from "./utils";

function Deal({ deal }) {
  const intl = useIntl();
  const endDate = useMemo(() => {
    if (deal.end_date) {
      const userFormat = Config.dateTimeFormat;
      const apiEndDate = getApiDate(deal.end_date, userFormat);
      const parsedEndDate = parseAPIDate(apiEndDate, userFormat);
      const endDate = checkNever(parsedEndDate);

      if (endDate !== "NEVER") {
        return lightFormat(endDate, userFormat);
      }
    }

    return intl.formatMessage({
      id: "LABEL_NEVER",
      defaultMessage: "Never",
    });
  }, [deal.end_date, intl]);

  return (
    <Table.Row key={deal.id}>
      <Table.Cell>{deal.title}</Table.Cell>
      <Table.Cell>{deal.status ? "Active" : "Inactive"}</Table.Cell>
      <Table.Cell>{deal.deal_id}</Table.Cell>
      <Table.Cell>{formatMoney(deal.price, deal.currency, Config.defaultLocale, 2)}</Table.Cell>
      <Table.Cell>{getApiDate(deal.start_date, Config.dateTimeFormat)}</Table.Cell>
      <Table.Cell>{endDate}</Table.Cell>
      <Table.Cell>{deal.supply_source_name}</Table.Cell>
      <Table.Cell>{deal.deal_type}</Table.Cell>
      <Table.Cell>
        <Link
          onClick={(e) => {
            e.stopPropagation();
          }}
          to={`/inventory/deals/edit/${deal.id}`}
        >
          <Icon
            link
            name="edit"
          />
        </Link>
      </Table.Cell>
    </Table.Row>
  );
}

export default Deal;
