import {
  addMinutes,
  addMonths,
  format as dateFormat,
  isAfter,
  isBefore,
  setMinutes,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import { Form, Icon, Input, Popup } from "semantic-ui-react";

import { Config } from "../../../config/api";

function budgetFlightsValidation(fields, intl) {
  const budgetUnderflowMsg = intl.formatMessage(
    {
      id: "VALIDATION_RANGE_UNDERFLOW",
      defaultMessage: "Value should be greater than zero",
    },
    { min_value: 1 }
  );

  const missingValueMsg = intl.formatMessage({
    id: "VALIDATION_VALUE_MISSING",
    defaultMessage: "Please fill out this field",
  });

  const oneHourBetweenMsg = intl.formatMessage({
    id: "VALIDATION_BUDGET_FLIGHT_ONEHR_BETWEEN",
    defaultMessage: "There must be 1 hour between flight",
  });

  return fields["budget_flights"].map((flight, index) => {
    const flightErrors = {};

    // Validates that budgets are positive
    if (flight["total_budget"] <= 0) {
      flightErrors["total_budget"] = [];
      flightErrors["total_budget"].push(budgetUnderflowMsg);
    }

    // Validates dates presence
    if (!flight["start_date"]) {
      if (!flightErrors["start_date"]) flightErrors["start_date"] = [];
      flightErrors["start_date"].push(missingValueMsg);
    }

    if (!flight["end_date"]) {
      if (!flightErrors["end_date"]) flightErrors["end_date"] = [];
      flightErrors["end_date"].push(missingValueMsg);
    }

    if (flight["start_date"] && flight["end_date"]) {
      // Validate range
      if (flight["start_date"] >= flight["end_date"]) {
        const msg = intl.formatMessage(
          {
            id: "VALIDATION_RANGE_OVERFLOW",
            defaultMessage: "Value should be less than end date",
          },
          { max_value: dateFormat(flight["end_date"], Config.dateTimeFormat) }
        );

        if (!flightErrors["start_date"]) flightErrors["start_date"] = [];
        flightErrors["start_date"].push(msg);
      }

      // Validate overlaps
      const overlapMsg = intl.formatMessage({
        id: "VALIDATION_BUDGET_FLIGHT_OVERLAP",
        defaultMessage: "Value must not overlap with other flights",
      });

      for (let j = 0; j < fields["budget_flights"].length; j++) {
        const f = fields["budget_flights"][j];
        if (j === index || !f["start_date"] || !f["end_date"]) continue;

        const startDateOverlaps =
          f["start_date"] <= flight["start_date"] &&
          f["end_date"] >= flight["start_date"];
        if (startDateOverlaps) {
          if (!flightErrors["start_date"]) flightErrors["start_date"] = [];
          flightErrors["start_date"].push(overlapMsg);
        }

        const endDateOverlaps =
          f["start_date"] <= flight["end_date"] &&
          f["end_date"] >= flight["end_date"];
        if (endDateOverlaps) {
          if (!flightErrors["end_date"]) flightErrors["end_date"] = [];
          flightErrors["end_date"].push(overlapMsg);
        }

        if (startDateOverlaps || endDateOverlaps) break;
      }
    }

    return Object.keys(flightErrors).length === 0 ? null : flightErrors;
  });
}

function changeFlight(flights, index, value, prop) {
  const newFlights = [...flights];
  newFlights[index][prop] = value;
  return newFlights;
}

function BudgetFlight(props) {
  const {
    index,
    values,
    intl,
    errors,
    setErrors,
    updateValues,
    isPG,
    currency,
  } = props;
  const flights = values["budget_flights"];
  const totalFlights = Object.keys(flights).length;
  const data = flights[index];
  const flightsErrors = errors["budget_flights"] || [];
  const flightError = flightsErrors[index] || {};
  const isTimeWindowPast = data["time_window"] === "past";
  const today = utcToZonedTime(new Date(), values.time_zone);
  let minStartDate = today;
  if (index > 0) {
    const lastPrevFlight = flights[index - 1];
    minStartDate = isBefore(lastPrevFlight.end_date, today)
      ? today
      : lastPrevFlight.end_date;
  }
  const maxStartDate = addMonths(minStartDate, 12);
  const minEndDate = isBefore(today, data.start_date) ? data.start_date : today;
  const maxEndDate = addMonths(setMinutes(minEndDate, 0), 12);
  const isFlightStarted = isBefore(data.start_date, today);
  const flightsWithTimeWindow = flights.filter((flight) =>
    Boolean(flight.time_window)
  );
  const isEndDateDisabled =
    index < flightsWithTimeWindow.length - 1 &&
    data["time_window"] &&
    isAfter(today, data.end_date);
  const canDelete = !data["t1_id"] || (!isFlightStarted && !isTimeWindowPast);

  const filterStartPassedTime = (time) => {
    const selectedDate = new Date(time);
    if (index === 0) {
      return minStartDate.getTime() < selectedDate.getTime();
    }
    return addMinutes(minStartDate, 5).getTime() < selectedDate.getTime();
  };

  const filterEndPassedTime = (time) => {
    const selectedStartDate = data["start_date"];
    const selectedDate = new Date(time);
    return selectedStartDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className="budget-flight__row">
      <Form.Group style={{ flex: 1 }} widths={4}>
        <Form.Field error={flightError.hasOwnProperty("start_date")} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_START_DATE",
              defaultMessage: "Start Date",
            })}
          </label>
          <DatePicker
            selected={data["start_date"]}
            filterTime={filterStartPassedTime}
            minDate={minStartDate}
            maxDate={maxStartDate}
            disabled={
              data["time_window"] && (isTimeWindowPast || isFlightStarted)
            }
            onChange={(date) => {
              const newFlights = changeFlight(
                flights,
                index,
                date,
                "start_date"
              );
              updateValues({ ...values, budget_flights: newFlights });
            }}
            showTimeInput
            timeFormat={Config.timeFormat}
            timeIntervals={15}
            timeCaption="time"
            dateFormat={Config.fullDateTimeFormat}
          />
          <div className="custom-error" style={{ margin: 0 }}>
            {flightError["start_date"] && flightError["start_date"][0]}
          </div>
        </Form.Field>
        <Form.Field error={flightError.hasOwnProperty("end_date")} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_END_DATE",
              defaultMessage: "End Date",
            })}
          </label>
          <DatePicker
            selected={data["end_date"]}
            filterTime={filterEndPassedTime}
            minDate={minEndDate}
            maxDate={maxEndDate}
            disabled={isEndDateDisabled && !isPG}
            onChange={(date) => {
              const newFlights = changeFlight(flights, index, date, "end_date");
              updateValues({ ...values, budget_flights: newFlights });
            }}
            showTimeInput
            timeFormat={Config.timeFormat ?? "HH:mm"}
            timeCaption="time"
            dateFormat={Config.fullDateTimeFormat}
          />
          <div className="custom-error" style={{ margin: 0 }}>
            {flightError["end_date"] && flightError["end_date"][0]}
          </div>
        </Form.Field>
        <Form.Field error={flightError.hasOwnProperty("total_budget")} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_TOTAL_BUDGET",
              defaultMessage: "Monetary Budget",
            })}
            {isPG && (
              <Popup
                inverted
                content={intl.formatMessage({
                  id: "HINT_BUDGET_PG",
                  defaultMessage:
                    "Budgets are for directional purposes only and do not cut off spend. Please ensure the budget cap is set in the publisher ad server per the Programmatic Guaranteed agreement.",
                })}
                size="mini"
                trigger={
                  <Icon
                    name="help circle"
                    style={{ position: "relative" }}
                    className="cursor-help"
                  />
                }
              />
            )}
          </label>
          <Input
            required
            type="number"
            min={0}
            step={1}
            places={1}
            disabled={isEndDateDisabled && !isPG}
            value={data["total_budget"]}
            label={currency}
            onChange={(e) => {
              const newFlights = changeFlight(
                flights,
                index,
                e.target.value,
                "total_budget"
              );
              updateValues({ ...values, budget_flights: newFlights });
            }}
          />
          <div className="custom-error" style={{ margin: 0 }}>
            {flightError["total_budget"] && flightError["total_budget"][0]}
          </div>
        </Form.Field>
        <Form.Field
          error={flightError.hasOwnProperty("total_impression_budget")}
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_TOTAL_IMPRESSION_BUDGET",
              defaultMessage: "Impression Budget",
            })}
          </label>
          <Input
            type="number"
            min={0}
            step={1}
            disabled={isEndDateDisabled && !isPG}
            value={data["total_impression_budget"]}
            label="#"
            onChange={(e) => {
              const newFlights = changeFlight(
                flights,
                index,
                e.target.value,
                "total_impression_budget"
              );
              updateValues({ ...values, budget_flights: newFlights });
            }}
          />
          <div className="custom-error" style={{ margin: 0 }}>
            {flightError["total_impression_budget"] &&
              flightError["total_impression_budget"][0]}
          </div>
        </Form.Field>
      </Form.Group>
      <div className="budget-flight__delete-control">
        {canDelete && totalFlights > 1 && (
          <Icon
            className="trash"
            onClick={() => {
              const newFlights = [
                ...flights.slice(0, index),
                ...flights.slice(index + 1, flights.length),
              ];
              const newErrors = [
                ...flightsErrors.slice(0, index),
                ...flightsErrors.slice(index + 1, flightsErrors.length),
              ];
              updateValues({ ...values, budget_flights: newFlights });
              setErrors({ ...errors, budget_flights: newErrors });
            }}
          />
        )}
      </div>
    </div>
  );
}

BudgetFlight.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  isPG: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};

export { BudgetFlight, budgetFlightsValidation };
