import React, {
  useMemo,
} from "react";

const FormattedCreativeStatus = ({ intl, status }) => {
  const statusConfig = useMemo(
    () => ({
      approved: {
        className: "green",
        message: intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_APPROVED",
          defaultMessage: "Approved",
        }),
      },
      pending: {
        className: "orange",
        message: intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_PENDING",
          defaultMessage: "Pending",
        }),
      },
      rejected: {
        className: "red",
        message: intl.formatMessage({
          id: "CREATIVE_APPROVAL_STATUS_REJECTED",
          defaultMessage: "Rejected",
        }),
      },
    }),
    [intl]
  );

  const { className = "", message = "-" } = statusConfig[status] || {};

  return <span className={className}>{message}</span>;
};

export { FormattedCreativeStatus };
