import { Dimmer, Image, Loader, Segment } from "semantic-ui-react";

const IMG_SRC = "https://react.semantic-ui.com/images/wireframe/paragraph.png";

const RouteFallback = () => {
  return (
    <Segment
      className="loading"
      disabled
      tertiary
      textAlign="center"
    >
      <Dimmer
        active
        inverted
      >
        <Loader size="large">Loading</Loader>
      </Dimmer>
      <Image src={IMG_SRC} />
    </Segment>
  );
};

export default RouteFallback;
