import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { xmlToJson } from '../../../libs/common_utils';

const VastPlayer = ({ vastTagUrl }) => {
  const [vastTagJson, setVastTagJson] = useState(null);
  const [vastTagMediaFiles, setVastTagMediaFiles] = useState([]);

  // Fetch and parse the VAST tag XML
  const fetchAndParseVastTag = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch VAST tag');
      const xmlString = await response.text();
      return xmlToJson(xmlString);
    } catch (error) {
      console.error('Error fetching and parsing VAST tag:', error);
    }
  }, []);

  // Recursively find attributes in the VAST JSON
  function findAttribute(data, targetAttribute) {
    if (typeof data === 'object' && !Array.isArray(data)) {
      if (Object.hasOwn(data, targetAttribute)) {
        return data[targetAttribute];
      }
      for (let key in data) {
        if (Object.hasOwn(data, key)) {
          const result = findAttribute(data[key], targetAttribute);
          if (result) {
            return result;
          }
        }
      }
    } else if (Array.isArray(data)) {
      for (let item of data) {
        const result = findAttribute(item, targetAttribute);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  // Function to handle nested VAST tags
  const handleNestedVastTag = async (vastTagJson) => {
    const nestedVastUri = findAttribute(vastTagJson, 'VASTAdTagURI');
    if (nestedVastUri) {
      const nestedVastTagJson = await fetchAndParseVastTag(nestedVastUri['#cdata']);

      if (nestedVastTagJson) {

        // Collect media files from the nested VAST tag
        const nestedMediaFiles = findAttribute(nestedVastTagJson, 'MediaFile');

        if (nestedMediaFiles) {
          // Append the media files from the nested VAST tag
          setVastTagMediaFiles([nestedMediaFiles]);
        }
        // Check for additional nested VAST tags recursively
        handleNestedVastTag(nestedVastTagJson);
      }
    }
  };

  // Load the initial VAST tag data
  useEffect(() => {
    const loadVastData = async () => {
      const vastTagData = await fetchAndParseVastTag(vastTagUrl);
      setVastTagJson(vastTagData);
    };
    loadVastData();
  }, [vastTagUrl]);

  // Handle the VAST tag JSON once it's loaded
  useEffect(() => {
    if (vastTagJson) {

      const mediaFiles = findAttribute(vastTagJson, 'MediaFile');

      if (mediaFiles?.length > 0) {
        setVastTagMediaFiles(mediaFiles);
      } else {
        // Handle nested VAST tags if no media files are found in the initial VAST
        handleNestedVastTag(vastTagJson);
      }
    }
  }, [vastTagJson]);

  return (
    <video controls>
      {vastTagMediaFiles.length > 0 && (
        vastTagMediaFiles.map((mediaFile) => (
          <source key={mediaFile['#cdata']} src={mediaFile['#cdata']} type={mediaFile['@attributes'].type} />
        ))
      )}
    </video>
  );
};

VastPlayer.propTypes = {
  vastTagUrl: PropTypes.string.isRequired,
};

export { VastPlayer };
