import sortBy from "lodash/sortBy";
import union from "lodash/union";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Config } from "../../../config/api";
import { useDebounce } from "../../../libs/component_utils";
import HttpConnect from "../../../libs/http_connect";
import AgenciesService from "../../../services/agencies";
import T1Service from "../../../services/t1";
import MillerPicker from "./miller-picker";

const agenciesService = new AgenciesService();
const t1Service = new T1Service();

const REF_KEYS = {
  agencyIds: "agencyIds",
  advertiserIds: "advertiserIds",
};

function selectedOnTop(selected, all = []) {
  const selectedSet = new Set(selected);
  return sortBy(all, ({ value }) => (selectedSet.has(value) ? -1 : 1));
}

const OrganizationPicker = ({
  values,
  initialIndeterminateAgencies = [],
  onChange,
  onError,
  refKeys = REF_KEYS,
  preventAdvertisers = false,
}) => {
  const intl = useIntl();
  const [agenciesLoading, setAgenciesLoading] = useState(true);
  const [agencies, setAgencies] = useState([]);
  const [advertisersLoading, setAdvertisersLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [indeterminateAgencies, setIndeterminateAgencies] = useState(
    new Set(preventAdvertisers ? [] : initialIndeterminateAgencies),
  );
  const [activeAgency, setActiveAgency] = useState(null);
  const [agencySearchQuery, setAgencySearchQuery] = useState("");
  const initialAgencies = useRef();
  const [hasError, setHasError] = useState();
  const [allAgencies, setAllAgencies] = useState([]);
  const initialAgenciesIds = useRef([...(values[refKeys.agencyIds] ?? []), ...initialIndeterminateAgencies]);

  const handleSelectAgency = useCallback(
    async (selectedValue) => {
      if (preventAdvertisers) {
        const newAgencyIds = new Set(values[refKeys.agencyIds]);
        if (newAgencyIds.has(selectedValue)) {
          newAgencyIds.delete(selectedValue);
        } else {
          newAgencyIds.add(selectedValue);
        }
        handleChangeAgencies([...newAgencyIds]);
        return;
      }

      HttpConnect.cancelRequest();
      setActiveAgency(selectedValue);
      setAdvertisersLoading(true);

      try {
        const response = await t1Service.advertisers(selectedValue);
        const sortedAdvertisers = selectedOnTop(
          values[refKeys.advertiserIds],
          response.data.map(({ id, title }) => ({
            key: id,
            text: title,
            value: id,
          })),
        );
        setAdvertisers(sortedAdvertisers);
        if (values[refKeys.agencyIds].includes(selectedValue)) {
          onChange({
            [refKeys.advertiserIds]: union(
              values[refKeys.advertiserIds],
              response.data.map(({ id }) => id),
            ),
          });
        }
      } catch (e) {
        onError(e);
      } finally {
        setAdvertisersLoading(false);
      }
    },
    [values[refKeys.agencyIds], onChange, onError, refKeys, preventAdvertisers],
  );

  const handleChangeAgencies = useCallback(
    (nextValue) => {
      if (!activeAgency) {
        return onChange({ [refKeys.agencyIds]: nextValue });
      }

      if (!nextValue.length && values[refKeys.agencyIds].length >= 2) {
        // all agencies have been unchecked by master checkbox, not one by one
        setIndeterminateAgencies(new Set());
        return onChange({
          [refKeys.agencyIds]: [],
          [refKeys.advertiserIds]: [],
        });
      }

      const prevValueSet = new Set(values[refKeys.agencyIds]);
      const nextValueSet = new Set(nextValue);
      let advertiserIds = values[refKeys.advertiserIds];

      if (prevValueSet.has(activeAgency) && !nextValueSet.has(activeAgency)) {
        // active agency has been unchecked
        const advertiserValuesSet = new Set(advertisers.map(({ value }) => value));
        advertiserIds = advertiserIds.filter((id) => !advertiserValuesSet.has(id));
        setIndeterminateAgencies((indeterminateAgencies) => {
          const nextVal = new Set(indeterminateAgencies);
          nextVal.delete(activeAgency);
          return nextVal;
        });
      }

      if (!prevValueSet.has(activeAgency) && nextValueSet.has(activeAgency)) {
        // active agency has been checked
        const advertiserValues = advertisers.map(({ value }) => value);
        advertiserIds = union(advertiserIds, advertiserValues);
      }

      onChange({
        [refKeys.agencyIds]: nextValue,
        [refKeys.advertiserIds]: advertiserIds,
      });
    },
    [activeAgency, advertisers, values, onChange, refKeys],
  );

  const performAgencySearch = async (query) => {
    if (query.trim() === "") {
      setAgencies(initialAgencies.current);
      return;
    }

    setAgenciesLoading(true);
    try {
      const response = await agenciesService.fetchAvailableAgencies(query.trim());
      const nextAgencies = selectedOnTop(values[refKeys.agencyIds], response.data).map(({ id, title }) => ({
        text: title,
        value: id,
      }));
      setAgencies(nextAgencies);
    } catch (e) {
      onError(e);
    } finally {
      setAgenciesLoading(false);
    }
  };

  const performAgencySearchDebounced = useDebounce(Config.search_debounce_delay, performAgencySearch);

  const handleChangeAdvertisers = useCallback(
    (value) => {
      const advertiserValues = advertisers.map(({ value }) => value);
      const valueSet = new Set(value);
      let agencyIds = values[refKeys.agencyIds];

      if (advertiserValues.every((v) => valueSet.has(v))) {
        agencyIds = [...agencyIds, activeAgency];
      } else {
        agencyIds = agencyIds.filter((id) => id !== activeAgency);
      }

      if (!advertiserValues.some((v) => valueSet.has(v))) {
        const nextVal = new Set(indeterminateAgencies);
        nextVal.delete(activeAgency);
        setIndeterminateAgencies(nextVal);
      } else if (!indeterminateAgencies.has(activeAgency)) {
        setIndeterminateAgencies(new Set(indeterminateAgencies).add(activeAgency));
      }

      onChange({
        [refKeys.advertiserIds]: value,
        [refKeys.agencyIds]: agencyIds,
      });
    },
    [activeAgency, advertisers, values, onChange, refKeys, indeterminateAgencies],
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await agenciesService.fetchAllAgencies();
        const newAllAgencies = response.data.map(({ id, title }) => ({
          text: title,
          value: id,
        }));
        setAllAgencies(newAllAgencies);
      } catch (e) {
        setHasError(e);
      } finally {
        setAgenciesLoading(false);
      }
    })();
    return () => {
      HttpConnect.cancelRequest();
      setAllAgencies([]);
    };
  }, []);

  useEffect(() => {
    if (allAgencies) {
      const sortedAgencies = selectedOnTop(initialAgenciesIds.current, allAgencies).slice(0, 100);
      setAgencies(sortedAgencies);
      initialAgencies.current = sortedAgencies;
    }
  }, [allAgencies]);

  useEffect(() => {
    if (hasError && onError) {
      onError(hasError);
    }
  }, [hasError, onError]);

  return (
    <MillerPicker disabled={agenciesLoading || advertisersLoading}>
      <MillerPicker.Column
        id="agencies_column"
        title={intl.formatMessage({
          id: "LABEL_AGENCIES",
          defaultMessage: "Agencies",
        })}
        loading={agenciesLoading}
        selection
        items={agencies}
        value={values[refKeys.agencyIds]}
        indeterminate={indeterminateAgencies}
        onChange={handleChangeAgencies}
        search={true}
        searchQuery={agencySearchQuery}
        searchPlaceholder={intl.formatMessage({
          id: "HINT_SEARCH",
          defaultMessage: "Search",
        })}
        onSearchChange={async (searchQuery) => {
          setAgencySearchQuery(searchQuery);
          await performAgencySearchDebounced(searchQuery);
        }}
        activeItem={activeAgency}
        onSelect={handleSelectAgency}
      />

      {activeAgency && (
        <MillerPicker.Column
          id="advertisers_column"
          title={intl.formatMessage({
            id: "LABEL_ADVERTISERS",
            defaultMessage: "Advertisers",
          })}
          loading={advertisersLoading}
          items={advertisers}
          value={values[refKeys.advertiserIds]}
          onChange={handleChangeAdvertisers}
        />
      )}
    </MillerPicker>
  );
};

export default memo(OrganizationPicker);
