import React, { useCallback, useState, useEffect, useContext } from "react";

import { Divider, Header, Message, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";
import UserForm from "../form";
import UsersService from "../../../services/users";
import { OnlineContext } from "../../../context/online-context";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.user_id
 * @return {*}
 * @constructor
 */
const UserEditPage = ({ history, match }) => {
  const back_url = "/admin/users";
  const service = new UsersService();

  const intl = useIntl();

  const user_id = parseInt(match.params.user_id, 10) || 0;
  const { t1UserId } = useContext(OnlineContext);

  let _isMounted = React.useRef(false);

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(back_url);
  }, []);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(back_url, { action: "error", msg: "User not found" });
  };

  /**
   * form submit handler
   * @return {boolean}
   */
  const handleSubmit = async (values) => {
    setLoading(true);

    const data = {
      ...values,
      email: values["username"],
      entities: [
        {
          entity_type: "agencies",
          entity_ids: values["agency_ids"],
        },
        {
          entity_type: "advertisers",
          entity_ids: values["advertiser_ids"],
        },
      ],
    };
    delete data["agency_ids"];
    delete data["advertiser_ids"];

    try {
      await service.update(user_id, data);
      history.push(back_url, {
        action: "updated",
        username: values["username"],
      });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  if (user_id < 1) {
    handle404();
  }

  if (user_id === t1UserId) {
    history.replace("/profile");
  }

  const [initialData, setInitialData] = useState(null);
  const [initialIndeterminateAgencies, setInitialIndeterminateAgencies] =
    useState([]);

  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await service.get(user_id);
        const newIndeterminateAgencies = r.data["entities"].find(
          (kind) =>
            kind["entity_type"] === "agencies" &&
            kind["entity_status"] === "partial"
        )["entity_ids"];
        setInitialIndeterminateAgencies(newIndeterminateAgencies);
        setInitialData({
          status: r.data["status"],
          first_name: r.data["first_name"],
          last_name: r.data["last_name"],
          title: r.data["title"],
          phone: r.data["phone"],
          username: r.data["username"],
          role: r.data["user_role"],
          agency_ids: r.data["entities"].find(
            (kind) =>
              kind["entity_type"] === "agencies" &&
              kind["entity_status"] === "full"
          )["entity_ids"],
          advertiser_ids: r.data["entities"].find(
            (kind) => kind["entity_type"] === "advertisers"
          )["entity_ids"],
        });
      } catch (e) {
        handle404();
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [user_id]);

  if (initialData === null) {
    return (
      <Segment disabled tertiary textAlign="center" className="loading">
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_EDIT_USER",
          defaultMessage: "Edit User",
        })}
      </Header>
      <Divider />
      <Message
        style={{ marginTop: "10px" }}
        error
        hidden={!serverError}
        size="tiny"
        content={serverError}
      />
      <UserForm
        initialData={initialData}
        initialIndeterminateAgencies={initialIndeterminateAgencies}
        formType="edit"
        loading={loading}
        submitText={intl.formatMessage({
          id: "BTN_SAVE_CHANGES",
          defaultMessage: "Save Changes",
        })}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Segment>
  );
};

export default UserEditPage;
