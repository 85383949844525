import {
  useState,
  useEffect,
  useMemo,
} from "react";

import { CreativePreviewerRenderer } from "./creative_previewer_renderer";
import CreativesService from "../../../services/creatives";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { useIntl } from "react-intl";

const formatCampaignData = (creativeData, intl) => {
  const displayData = [
    {
      label: intl.formatMessage({ id: "LABEL_DIMENSIONS", defaultMessage: "Dimensions" }),
      value: `${creativeData.data.width} x ${creativeData.data.height}`,
    },
    {
      label: intl.formatMessage({ id: "LABEL_MRAID", defaultMessage: "MRAID" }),
      value: creativeData.data.mraid ? "True" : "False",
    },
  ];

  const sharedData = [
    {
      label: intl.formatMessage({ id: "LABEL_CREATIVE_NAME", defaultMessage: "Creative Name" }),
      value: creativeData.data.creative_name,
    },
    {
      label: intl.formatMessage({ id: "LABEL_CREATIVE_ID", defaultMessage: "Creative ID" }),
      value: creativeData.data.id,
    },
    {
      label: intl.formatMessage({ id: "LABEL_CONCEPT_NAME", defaultMessage: "Concept Name" }),
      value: creativeData.data.concept_name,
    },
    {
      label: intl.formatMessage({ id: "LABEL_CONCEPT_ID", defaultMessage: "Concept ID" }),
      value: creativeData.data.concept_id,
    },
    {
      label: intl.formatMessage({ id: "LABEL_SECURE", defaultMessage: "Secure" }),
      value: creativeData.data.secure ? "True" : "False",
    },
    {
      label: intl.formatMessage({ id: "LABEL_FILE_TYPE", defaultMessage: "File Type" }),
      value: creativeData.data.file_type,
    },
    creativeData.data.click_through_url && {
      label: intl.formatMessage({ id: "LABEL_CLICKTHROUGH_URL", defaultMessage: "Clickthrough URL" }),
      value: <Link to={creativeData.data.click_through_url} target="_blank">{creativeData.data.click_through_url}</Link>,
    },
    creativeData.data.landing_page_url && {
      label: intl.formatMessage({ id: "LABEL_LANDING_PAGE_URL", defaultMessage: "Landing Page URL" }),
      value: <Link to={creativeData.data.landing_page_url} target="_blank">{creativeData.data.landing_page_url}</Link>,
    }
  ].filter(Boolean);

  const videoData = [
    creativeData.data?.bitrates?.length > 0 && {
      label: intl.formatMessage({ id: "LABEL_RESOLUTION_BITRATE", defaultMessage: "Resolution (Bitrate)" }),
      value: creativeData.data.bitrates.join(", "),
    },
  ].filter(Boolean);

  const formattedCampaignData = creativeData.data.file_type === "video" || creativeData.data.file_type === "vast" ? [...sharedData, ...videoData] : [...sharedData, ...displayData];

  return formattedCampaignData;
};

const CreativePreviewer = ({
  creativeId,
  hideDataTables,
}) => {
  const [creativePreviewData, setCreativePreviewData] = useState({});
  const [formattedCampaignData, setFormattedCampaignData] = useState([]);

  const intl = useIntl();
  const services = useMemo(() => new Map([["creatives", new CreativesService()]]), []);

  /**
   * Fetch creative preview by creative id
   */
  const getCreativePreviewData = async (id) => {
    try {
      const data = await services.get("creatives").get_creative_preview(id);
      setCreativePreviewData(data);

      const formattedCampaignData = formatCampaignData(data, intl);
      setFormattedCampaignData(formattedCampaignData);

    } catch (e) {
      console.error("Error fetching creative preview data:", e);
    }
  };

  useEffect(() => {
    getCreativePreviewData(creativeId);
  }, [creativeId]);

  return (
    <Table basic="very" style={{ width: "100%" }}>
      <Table.Body>
        {!hideDataTables &&
          formattedCampaignData.map((item) => (
            <Table.Row key={item.label}>
              <Table.Cell style={{ whiteSpace: "nowrap" }}>
                <strong>{item.label}:</strong>
              </Table.Cell>
              <Table.Cell style={{ width: "100%" }}>{item.value}</Table.Cell>
            </Table.Row>
          ))}

        <Table.Row>
          <Table.Cell colSpan="2" textAlign="center">
            <CreativePreviewerRenderer creativePreviewData={creativePreviewData} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export { CreativePreviewer };
