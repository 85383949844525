import { useIntl } from "react-intl";
import { Modal } from "semantic-ui-react";

const CONTENT_ID = "BODY_ADBLOCK";
const CONTENT_MSG = "To ensure an optimal experience, please disable your ad block provider.";
const HEADER_ID = "HEADING_ADBLOCK";
const HEADER_MSG = "Ad Blocker Detected";

const AdblockWarningModal = () => {
  const { formatMessage } = useIntl();
  const headerTxt = formatMessage({ defaultMessage: HEADER_MSG, id: HEADER_ID });
  const contentTxt = formatMessage({ defaultMessage: CONTENT_MSG, id: CONTENT_ID });

  return (
    <Modal
      dimmer="blurring"
      open
      size="mini"
    >
      <Modal.Header>{headerTxt}</Modal.Header>
      <Modal.Content>{contentTxt}</Modal.Content>
    </Modal>
  );
};

export default AdblockWarningModal;
