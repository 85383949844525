export default class CampaignsBulkEditModel {
	constructor({
		ids = null,
		status = null,
		frequencyOptimization = null,
		frequencyType = null,
		frequencyAmount = null,
		frequencyInterval = null,
	} = {}) {
		const model = {
			ids: ids,
			status: status !== null ? parseInt(status) : null,
			frequency_optimization: frequencyOptimization,
			frequency_type: frequencyType,
			frequency_amount: frequencyAmount !== null ? parseInt(frequencyAmount) : null,
			frequency_interval: frequencyInterval,
		};

		Object.keys(model).forEach(key => model[key] === null && delete model[key]);

		return model;
	}
}
