import PropTypes from "prop-types";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Loader,
  Radio,
} from "semantic-ui-react";

import { errorToMessage } from "../../../libs/common_utils";
import { useForm } from "../../../libs/component_utils";
import PasswordsService from "../../../services/passwords";
import OrganizationPicker from "../../common/organization-picker";
import { user_roles } from "../fixtures";

/**
 * User create page component
 * @return {*}
 * @constructor
 */
const UserForm = ({
  initialData,
  loading,
  onSubmit,
  onCancel,
  initialIndeterminateAgencies = [],
  formType = "create",
  submitText = "Upload Device ID",
}) => {
  /**
   * form submit handler
   * @return {boolean}
   */
  const UserForm = async () => {
    await onSubmit(values);
  };

  const intl = useIntl();
  const {
    values,
    updateValues,
    errors,
    onChange,
    onSubmit: handleSubmit,
    setErrors,
  } = useForm(UserForm, initialData, () => {
    const errors = {};

    if (
      formType !== "profile" &&
      !values.agency_ids.length &&
      !values.advertiser_ids.length
    ) {
      errors.agency_ids = intl.formatMessage({
        id: "ERROR_EMPTY_ENTITIES",
        defaultMessage: "Please select at least one agency or advertiser.",
      });
    }

    return errors;
  });

  const services = React.useRef(
    new Map([["passwords", new PasswordsService()]])
  );
  const [passwordLoading, setPasswordLoading] = useState();
  const [passwordError, setPasswordError] = useState();
  const [passwordFinal, setPasswordFinal] = useState();
  const onResetPassword = async () => {
    setPasswordLoading(true);
    try {
      const response = await services.current.get("passwords").create({
        email: values.username,
      });
      if (response.meta.status === "Error") {
        throw response;
      } else {
        setPasswordFinal(
          intl.formatMessage({
            id: "BODY_RESET_PWD_SENT",
            defaultMessage:
              "We’ve just sent you an email to reset your password.",
          })
        );
      }
    } catch (e) {
      setPasswordError(`Error: ${e.message || e.error.message}`);
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      size="small"
      loading={loading}
      noValidate
      error={!!Object.keys(errors).length}
      autoComplete="off"
    >
      {formType !== "profile" && (
        <Form.Field inline>
          <label>
            {intl.formatMessage({
              id: "LABEL_STATUS",
              defaultMessage: "Status",
            })}
          </label>
          <Radio
            name="status"
            label={intl.formatMessage({
              id: "STATUS_ACTIVE",
              defaultMessage: "Active",
            })}
            value={1}
            checked={!!values.status}
            onChange={onChange}
          />
          <Radio
            style={{ marginLeft: "15px" }}
            name="status"
            label={intl.formatMessage({
              id: "STATUS_INACTIVE",
              defaultMessage: "Inactive",
            })}
            value={0}
            checked={!values.status}
            onChange={onChange}
          />
        </Form.Field>
      )}

      <Form.Field inline error={errors.hasOwnProperty("first_name")} required>
        <label>
          {intl.formatMessage({
            id: "LABEL_FIRST_NAME",
            defaultMessage: "First name",
          })}
        </label>
        <Input
          name="first_name"
          required
          minLength={1}
          maxLength={32}
          defaultValue={values.first_name}
          onBlur={onChange}
        />
        <div className="custom-error">{errors["first_name"]}</div>
      </Form.Field>

      <Form.Field inline error={errors.hasOwnProperty("last_name")} required>
        <label>
          {intl.formatMessage({
            id: "LABEL_LAST_NAME",
            defaultMessage: "Last name",
          })}
        </label>
        <Input
          name="last_name"
          required
          minLength={1}
          maxLength={32}
          defaultValue={values.last_name}
          onBlur={onChange}
        />
        <div className="custom-error">{errors["last_name"]}</div>
      </Form.Field>

      <Form.Field inline error={errors.hasOwnProperty("title")} required>
        <label>
          {intl.formatMessage({
            id: "LABEL_TITLE",
            defaultMessage: "Title",
          })}
        </label>
        <Input
          name="title"
          required
          minLength={1}
          maxLength={64}
          defaultValue={values.title}
          onBlur={onChange}
        />
        <div className="custom-error">{errors["title"]}</div>
      </Form.Field>

      <Form.Field inline error={errors.hasOwnProperty("phone")} required>
        <label>
          {intl.formatMessage({
            id: "LABEL_PHONE",
            defaultMessage: "Phone",
          })}
        </label>
        <Input
          name="phone"
          type="tel"
          required
          minLength={8}
          maxLength={24}
          pattern="^[\d ()+-]+$"
          title={intl.formatMessage({
            id: "ERROR_PHONE_PATTERN",
            defaultMessage: "Phone may only include digits, spaces, +, -, ()",
          })}
          defaultValue={values.phone}
          onBlur={onChange}
        />
        <div className="custom-error">{errors["phone"]}</div>
      </Form.Field>

      {formType === "create" ? (
        <Form.Field inline error={errors.hasOwnProperty("username")} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_EMAIL",
              defaultMessage: "Email",
            })}
          </label>
          <Input
            name="username"
            type="email"
            required
            minLength={1}
            maxLength={128}
            defaultValue={values.username}
            onBlur={onChange}
          />
          <span style={{ marginLeft: 20, color: "rgba(0, 0, 0, .6)" }}>
            {intl.formatMessage({
              id: "HINT_EMAIL",
              defaultMessage: "Email is the username",
            })}
          </span>
          <div className="custom-error">{errors["username"]}</div>
        </Form.Field>
      ) : (
        <Form.Field inline required>
          <label>
            {intl.formatMessage({
              id: "LABEL_EMAIL",
              defaultMessage: "Email",
            })}
          </label>
          <span>{values.username}</span>
        </Form.Field>
      )}

      {formType !== "create" && (
        <Form.Field inline error={Boolean(passwordError)} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_PASSWORD",
              defaultMessage: "Password",
            })}
          </label>
          {!passwordFinal ? (
            <>
              <button
                type="button"
                disabled={passwordLoading}
                className="pseudo-link"
                onClick={onResetPassword}
              >
                {intl.formatMessage({
                  id: "BTN_RESET_PWD_ADMIN",
                  defaultMessage: "Click to send password reset email",
                })}
              </button>
              <Loader
                size="mini"
                active={passwordLoading}
                inline
                style={{ marginLeft: 7, verticalAlign: "text-bottom" }}
              />
            </>
          ) : (
            <span>{passwordFinal}</span>
          )}
          <div className="custom-error">{passwordError}</div>
        </Form.Field>
      )}

      {formType !== "profile" && (
        <Form.Field inline error={errors.hasOwnProperty("role")} required>
          <label>
            {intl.formatMessage({
              id: "LABEL_USER_ROLE",
              defaultMessage: "User Role",
            })}
          </label>

          <Dropdown
            required
            selection
            options={Object.entries(user_roles(intl)).map(([value, text]) => ({
              key: value,
              text,
              value,
            }))}
            // loading={pageData.agenciesLoading}
            placeholder={intl.formatMessage({
              id: "HINT_USER_ROLE",
              defaultMessage: "Select role",
            })}
            name="role"
            value={values.role}
            onChange={onChange}
          />
          <div className="custom-error">{errors["role"]}</div>
        </Form.Field>
      )}

      {formType !== "profile" && (
        <Form.Field
          inline
          required
          error={Boolean(errors["agency_ids"] || errors["advertiser_ids"])}
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_ENTITIES",
              defaultMessage: "Entities",
            })}
          </label>

          <OrganizationPicker
            values={values}
            // these will go passed from backend on edit page, always empty on create page:
            initialIndeterminateAgencies={initialIndeterminateAgencies}
            onChange={updateValues}
            onError={(error) =>
              setErrors({
                ...errors,
                agency_ids:
                  errorToMessage(error) ||
                  "Unknown error fetching data from server",
              })
            }
            refKeys={{
              agencyIds: "agency_ids",
              advertiserIds: "advertiser_ids",
            }}
          />
          <div className="custom-error">
            {errors["agency_ids"] || errors["advertiser_ids"]}
          </div>
        </Form.Field>
      )}

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Form.Field align="right">
        <Button size="tiny" type="button" onClick={onCancel}>
          {intl.formatMessage({
            id: "BTN_CANCEL",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button size="tiny" color="green" type="submit">
          {submitText}
        </Button>
      </Form.Field>
    </Form>
  );
};
UserForm.propTypes = {
  formType: PropTypes.string,
};

export default UserForm;
