import React, { useState, useCallback, useMemo } from "react";
import Pacing from "../../../models/object_value/pacing";
import Frequency from "../../../models/object_value/frequency";
import { campaignsActions as cA } from "../index/reducers";
import { useIntl } from "react-intl";
import { useForm } from "../../../libs/component_utils";
import CampaignsBulkEditModel from "../../../models/campaigns_bulk_edit";
import { BulkEditModalComponent } from "../../common/bulk_edit_modal";
import CampaignsService from "../../../services/campaign";
import { renameFrequencyKeys } from "../../../libs/common_utils";

const fields = ['status', 'frequencyCap'];

export const CampaignsBulkEditModal = ({isBulkEditModalOpen, setIsBulkEditModalOpen, selectedCampaigns, items, dispatch, setBulkEditResponseMessage}) => {
  const [checkedFields, setCheckedFields] = useState([]);
	const [formSending, setFormSending] = useState(false);
	const [serverError, setServerError] = useState("");
	const service = new CampaignsService();
  const intl = useIntl();
  const { formatMessage } = intl;
  const initialData = {
    "ids": selectedCampaigns,
    "status": null,
    "frequencyOptimization": null,
    "frequencyType": null,
    "frequencyAmount": null,
    "frequencyInterval": null,
  }

  const clearMessages = () => {
    setServerError("");
  }

  const handleResponse = ({ data = [], errors = [] }) => {
    const successMessage = data.map(({ id, title }) => ({ id, name: title }));
    const errorMessage = errors.map(({ message }) => ({ message }));

    setBulkEditResponseMessage({ successMessage, errorMessage });
  };

  const updateStateForCampaigns = (response, model) => {
    let { ids, ...fieldsToUpdate } = model;
    fieldsToUpdate = renameFrequencyKeys(fieldsToUpdate);
    const successfulIDs = response.data.map(campaign => campaign.id);

    const updatedItems = items.map(item => {
      if (successfulIDs.includes(item.id)) {
        return {
          ...item,
          ...fieldsToUpdate
        };
      }
      return item;
    });

    dispatch({ type: cA.UPDATE, data: updatedItems });
  };

	const postValidation = (_e, flds) => {
    const errors = {};
    const { frequencyAmount, minBid, maxBid, pacingAmount } = flds;

		if(frequencyAmount && !Frequency.isValid(frequencyAmount)) {
			errors["frequencyAmount"] = formatMessage({
				id: "ERROR_FREQUENCY_AMOUNT_OUTSIDE_MIN_MAX",
				defaultMessage: "Frequency amount must be a positive number between {min} and {max}",
			}, {
				min: Frequency.MIN,
				max: Frequency.MAX,
			});
		}

		return errors;
	};

  const editBulkCampaignsForm = async (params) => {
    try {
      setFormSending(true);
      clearMessages();
      const model = new CampaignsBulkEditModel(params);
      const response = await service.bulkEditCampaigns(model);
      handleResponse(response);
      updateStateForCampaigns(response, model);
      setFormSending(false);
      handleOnClose();
    } catch (e) {
      setServerError(e.error.message);
      setFormSending(false);
    };
  };

  const {
		values,
		errors,
    onSwitch,
		updateValues,
		onChange,
		onSubmit,
    removeError
	} = useForm(editBulkCampaignsForm, initialData, postValidation);

  const updateCheckboxActions = useMemo(() => ({
    status: {
      checked: () => updateValues({ status: 1 }),
      unchecked: () => updateValues({ status: null })
    },
    frequencyCap: {
      checked: () => updateValues({
        frequencyOptimization: 1
      }),
      unchecked: () => {
        updateValues({
          frequencyOptimization: null,
          frequencyType: null,
          frequencyAmount: null,
          frequencyInterval: null
        });
        removeError('frequencyAmount');
      }
    }
  }), [updateValues, removeError]);

  const handleCheckboxChange = useCallback((_e, { name, checked }) => {
    if (checked) {
      setCheckedFields((prevFields) => [...prevFields, name]);
      updateCheckboxActions[name].checked();
    } else {
      setCheckedFields((prevFields) => prevFields.filter((field) => field !== name));
      updateCheckboxActions[name].unchecked();
    }
  }, [updateCheckboxActions]);

  const handleOnClose = useCallback(() => {
    fields.forEach((field) => {
      updateCheckboxActions[field].unchecked();
    });
    setCheckedFields([]);
    clearMessages();
    setIsBulkEditModalOpen(false);
  }, [fields, updateCheckboxActions]);

  const formActions = useMemo(() => ({
    onSwitch,
    onChange,
    onSubmit,
    handleOnClose,
    handleCheckboxChange,
    updateValues
  }), [onSwitch, onChange, onSubmit, handleOnClose, handleCheckboxChange, updateValues]);

  const formStates = useMemo(() => ({
    values,
    errors,
    formSending,
    selectedItems: selectedCampaigns,
    checkedFields
  }), [values, errors, formSending, selectedCampaigns, checkedFields]);

  const selectedPGCampaigns = useMemo(() => {
    return items
      .filter(campaign => selectedCampaigns.includes(campaign.id) && campaign.is_programmatic_guaranteed)
      .map(campaign => ({ id: campaign.id, name: campaign.title }));
  }, [items, selectedCampaigns]);

  return (
    <BulkEditModalComponent
      isBulkEditModalOpen={isBulkEditModalOpen}
      setIsBulkEditModalOpen={setIsBulkEditModalOpen}
      type={"campaign"}
      serverError={serverError}
      formActions={formActions}
      formStates={formStates}
      fields={fields}
      selectedPGCampaigns={selectedPGCampaigns}
    />
  );
};
