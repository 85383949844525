import { useContext } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Grid, Message } from "semantic-ui-react";

import InventoryPageContext from "../context";
import DealFilters from "./DealFilters";
import DealsGrid from "./DealsGrid";

function Deals() {
  const intl = useIntl();
  const history = useHistory();
  const { state } = useContext(InventoryPageContext);

  return (
    <>
      {state.deals.error && (
        <Message
          content={state.deals.errorMessage}
          error
          hidden={!state.deals.error}
          size="tiny"
          style={{ marginTop: "10px" }}
        />
      )}
      <Grid className="common_grid margin__top20">
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <DealFilters />
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            verticalAlign="middle"
            width={4}
          >
            <Button
              className="text__uppercase"
              compact
              onClick={() => history.push("/inventory/deals/create")}
              primary
            >
              {intl.formatMessage({
                id: "BTN_ADD_DEAL",
                defaultMessage: "Add Deal",
              })}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DealsGrid />
    </>
  );
}

export default Deals;
