import { useCallback, useEffect } from "react";

const DynamicIframe = ({ creativeID, iframeContent, iframeWidth, iframeHeight, iframeTitle, isMRAID }) => {
  const handleIframeContent = useCallback(() => {
    if (!isMRAID) return;

    const iframeID = `webtester-iframe${creativeID}`;
    const iframe = document.getElementById(iframeID);

    if (iframe) {
      const iframeLoadHandler = () => {
        if (iframe.contentWindow?.receiveAndSetProps) {
          iframe.contentWindow.receiveAndSetProps(iframeContent, iframeWidth, iframeHeight);
        }
      };

      iframe.addEventListener("load", iframeLoadHandler);

      return () => {
        iframe.removeEventListener("load", iframeLoadHandler);
      };
    }
  }, [isMRAID, iframeContent, iframeWidth, iframeHeight, creativeID]);

  useEffect(() => {
    handleIframeContent();
  }, [handleIframeContent]);

  return isMRAID ? (
    <iframe
      id={`webtester-iframe${creativeID}`}
      style={{
        display: "block",
        width: "100%",
        border: "none",
        height: iframeHeight ? `${iframeHeight}px` : "100%",
      }}
      title={iframeTitle || ""}
      src="/mraid-webtester/index.html"
    />
  ) : (
    <iframe
      id={`html-iframe${creativeID}`}
      srcDoc={iframeContent}
      height={iframeHeight ? Number.parseInt(iframeHeight) + 50 : "100%"}
      width={iframeWidth ? Number.parseInt(iframeWidth) + 50 : "100%"}
      style={{ padding: "0px", margin: "0px", border: "0px" }}
      title={iframeTitle || ""}
    />
  );
};

export { DynamicIframe };
