import React, { useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon, Popup, Table, Checkbox } from "semantic-ui-react";

import { Config } from "../../../config/api";
import {
	currencyCodeToSymbol,
	formatFrequencyCap,
	formatMoney,
	formatNumber,
	getApiDate,
} from "../../../libs/common_utils";
import { isNewTabClick } from "../../../libs/component_utils";
import CampaignGridContext from "../index/context";

/**
 * Generate grid item
 * @param {object} item
 * @param {boolean} item.cellVisible
 * @param {string} item.currency_code
 * @param {number} item.status
 * @param {string} item.title
 * @param {Date} item.start_date
 * @param {Date} item.end_date
 * @param {number} item.budget
 * @param {number} item.spend_to_date
 * @param {number} item.ctr
 * @param {number} item.clicks
 * @param {number} item.impressions
 * @param {number} item.id
 * @return {*}
 * @constructor
 */
export const CampaignGridItem = (item) => {
	const intl = useIntl();
	const context = useContext(CampaignGridContext);
	const cellVisible = item.cellVisible;
	const currency = currencyCodeToSymbol(item.currency_code);
  const { setSelectedCampaigns, selectedCampaigns } = context;

  const handleCheckboxChange = useCallback((e, { checked }) => {
    if (checked) {
      setSelectedCampaigns(prevCampaigns => [...prevCampaigns, item.id]);
    } else {
      setSelectedCampaigns(prevCampaigns => prevCampaigns.filter(id => id !== item.id));
    }
  }, [item.id]);
  const isCampaignChecked = selectedCampaigns.includes(item.id);

  const handleOnClick = useCallback((e) => {
    // Prevent the checkbox column from navigating to strategies page
    if (e.target.closest('.check-box-column')) {
      e.stopPropagation();
    } else {
      context.navigateToStrategiesPage(item.id);
    }
  }, [item.id]);

	return (
		<>
			<Table.Row
				className={
					"hoverable cursor-pointer" + (item.status ? "" : " inactive")
				}
				onClick={handleOnClick}
			>
        <Table.Cell className='check-box-column'>
          <Checkbox
            name={item.title}
            checked={isCampaignChecked}
            onChange={handleCheckboxChange}
          />
        </Table.Cell>
				<Table.Cell className="grid-item-col-name text-ellipsis">
					<Popup
						size="tiny"
						content={item.title}
						inverted
						trigger={
							<Link
								onClick={(e) => {
									e.stopPropagation();
								}}
								to={`/campaign/${item.id}/strategies`}
							>
								{item.title}
							</Link>
						}
					/>
				</Table.Cell>
				<Table.Cell className={cellVisible("status")}>
					{item.status
						? intl.formatMessage({
								id: "STATUS_ACTIVE",
								defaultMessage: "Active",
						  })
						: intl.formatMessage({
								id: "STATUS_INACTIVE",
								defaultMessage: "Inactive",
						  })}
				</Table.Cell>
				<Table.Cell className={cellVisible("start_date")}>
					{getApiDate(item.start_date, Config.dateTimeFormat)}
				</Table.Cell>
				<Table.Cell className={cellVisible("end_date")}>
					{getApiDate(item.end_date, Config.dateTimeFormat)}
				</Table.Cell>
				<Table.Cell className={cellVisible("campaign_budget")}>
					{formatMoney(
						item.budget,
						item.currency_code,
						Config.defaultLocale,
						2
					)}
				</Table.Cell>
				<Table.Cell className={cellVisible("campaign_spend")}>
					{item.spend_to_date?
            formatMoney(
              item.spend_to_date,
              item.currency_code,
              Config.defaultLocale,
              2
            )
          : "-"}
				</Table.Cell>
				<Table.Cell className={cellVisible("campaign_ctr")}>
					{item.ctr ? `${item.ctr} %` : "-"}
				</Table.Cell>
				<Table.Cell className={cellVisible("campaign_clicks")}>
					{item.clicks? formatNumber(item.clicks, Config.defaultLocale) : "-"}
				</Table.Cell>
				<Table.Cell className={cellVisible("campaign_impressions")}>
					{item.impressions? formatNumber(item.impressions, Config.defaultLocale) : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("total_conversions")}>
          {item.total_conversions? formatNumber(item.total_conversions, Config.defaultLocale) : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("total_revenue")}>
          {item.total_revenue?
            formatMoney(
              item.total_revenue,
              item.currency_code,
              Config.defaultLocale,
						  2
            )
          : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("total_spend_cpa")}>
          {item.total_spend_cpa?
            formatMoney(
              item.total_spend_cpa,
              item.currency_code,
              Config.defaultLocale,
              2)
          : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("total_spend_cpc")}>
          {item.total_spend_cpc?
            formatMoney(
              item.total_spend_cpc,
              item.currency_code,
              Config.defaultLocale,
              2
            )
          : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("total_spend_cpm")}>
          {item.total_spend_cpm?
            formatMoney(
              item.total_spend_cpm,
              item.currency_code,
              Config.defaultLocale,
              2
            )
          : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("goal_type")}>
					{item.goal_type? item.goal_type.toUpperCase() : "-"}
				</Table.Cell>
        <Table.Cell className={cellVisible("goal_value")}>
          {item.goal_value?
            formatMoney(
              item.goal_value,
              item.currency_code,
              Config.defaultLocale,
              2
            )
          : "-"}

				</Table.Cell>
        <Table.Cell className={cellVisible("frequency_cap")}>
          {formatFrequencyCap(
            intl,
            item.campaign_frequency_optimization,
            item.campaign_frequency_type,
            item.campaign_frequency_amount,
            item.campaign_frequency_interval
          )}
        </Table.Cell>
				<Table.Cell data-clickable="0" textAlign="center">
					<a
						href={null}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							context.openChartAccordion();
							context.setCampaignSelected(item);
						}}
					>
						<Icon name="line graph" className="control" />
					</a>
					&nbsp;
					<a
						href={null}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							context.setCampaignForDuplication(item);
						}}
					>
						<Icon name="copy outline" className="control" />
					</a>
					<a
						href={context.getEditPageHref(item.id)}
						onClick={(e) => {
							if (isNewTabClick(e)) return;
							e.preventDefault();
							e.stopPropagation();
							context.navigateToEditPage(item.id);
						}}
					>
						<Icon name="edit" className="control" />
					</a>
				</Table.Cell>
			</Table.Row>
		</>
	);
};
