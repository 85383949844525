import AbstractService from "../abstract.service";
import { formatAPIDateTime } from "../../libs/common_utils";

export default class CampaignsService extends AbstractService {
	static URI_STATES = new Map([
		["campaigns.duplicate", "/duplicate"],
		["campaigns.get", "/campaign/{0}"],
		["campaigns.data", "/campaigns-data2/{0}"],
		["campaigns.by_advertiser", "/campaigns/{0}"],
		["campaigns.create", "/campaign"],
		["campaigns.update", "/campaign/{0}"],
    ["campaigns.bulk_edit_campaigns", "/bulk-update/campaigns"],
	]);

	/**
	 * Get campaign by id
	 * @param {number} id
	 * @return {Promise}
	 */
	async get(id) {
		return await this.api_server.get(CampaignsService.URI_STATES.get("campaigns.get").format(id));
	}

	/**
	 * Get campaigns by advertiser
	 * @param {number} advertiser
	 * @param {object} params
	 * @return {Promise}
	 */
	async get_by_advertiser(advertiser, params) {
		return await this.api_server.get(CampaignsService.URI_STATES.get("campaigns.by_advertiser").format(advertiser),
			params);
	}

	/**
	 * Get user out
	 * @param {number} agency_id
	 * @param {object} params
	 * @returns {Promise<object>}
	 */
	async list(agency_id, params) {
		return this.api_server.get(
			CampaignsService.URI_STATES.get("campaigns.data").format(agency_id),
			params
		);
	}

	/**
	 * Create an agency
	 * @param {object} values - values of the create campaign form
	 * @returns {object} created campaign
	 */
	async create(values) {
		const flights = Object.values(values["budget_flights"]).map(flight => {
			const data = {
				"total_budget": parseFloat(flight["total_budget"]),
				"start_date": formatAPIDateTime(flight["start_date"]),
				"end_date": formatAPIDateTime(flight["end_date"])
			}

			if(flight["total_impression_budget"]) data["total_impression_budget"] = parseFloat(flight["total_impression_budget"]);

			return data;
		});

		const data = {
			...values,
			"budget_flights": flights,
			"status": values["status"] ? 1 : 0,
			"spend_cap_automatic": values["spend_cap_automatic"] ? 1 : 0,
			"goal_value": parseFloat(values["goal_value"] || 0),
			"spend_cap_amount": parseFloat(values["spend_cap_amount"] || 0),
			"margin_pct": values["margin_pct"] === null ? null : parseFloat(values["margin_pct"] || 0),
			"frequency_amount": parseInt(values["frequency_amount"] || 0, 10),
		};

		if(data["margin_pct"] === null) delete data["margin_pct"];
		if(data["site_list_ids"].length == 1) {
			const id = data["site_list_ids"][0];
			data["site_list_id"] = id;
		}
		delete data["site_list_ids"];

		// Right now backend attempts to validate some values even if they aren't used,
		// but does work alright if they aren't present at all, so we delete them as
		// a temporary measure:

		if (values["campaign_type"].toLowerCase() === "pg") {
			delete data["spend_cap_type"];
			delete data["spend_cap_automatic"];
			delete data["spend_cap_amount"];
			delete data["frequency_optimization"];
			delete data["frequency_type"];
			delete data["frequency_amount"];
			delete data["site_list_id"];
		} else {
			if (values["spend_cap_type"] === "no-limit") {
				delete data["spend_cap_automatic"];
				delete data["spend_cap_amount"];
			} else if (values["spend_cap_automatic"] === 1) {
				delete data["spend_cap_amount"];
			}

			if (values["frequency_optimization"]) {
				delete data["frequency_type"];
				delete data["frequency_amount"];
				delete data["frequency_interval"];
			} else if (values["frequency_type"] === "no-limit") {
				delete data["frequency_amount"];
				delete data["frequency_interval"];
			}
		}

		return this.api_server.post(
			CampaignsService.URI_STATES.get("campaigns.create"),
			data
		);
	}

	/**
	 * Update campaign
	 * @param {number} campaign_id
	 * @param {object} params
	 * @return {Promise}
	 */
	async update(campaign_id, params) {
		return this.api_server.put(
			CampaignsService.URI_STATES.get("campaigns.update").format(campaign_id),
			params
		);
	}

	async duplicate(campaignId) {
		return this.api_server.post(
			CampaignsService.URI_STATES.get("campaigns.duplicate"),
			{ type: "campaign", entity_id: campaignId }
		)
	}

  /**
	 * Bulk edits campaigns
	 * @param {object} params
	 * @return {Promise<>}
	 */
    async bulkEditCampaigns(params) {
      return this.api_server.post(CampaignsService.URI_STATES.get("campaigns.bulk_edit_campaigns"), params);
    }
}
